import { LayoutModule } from '@angular/cdk/layout';
import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/da';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import {
    BrowserModule,
    BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import {
    apiHttpStateInterceptorProvider,
    apiInterceptorProvider,
    DEV_ENVIRONMENT_HOSTNAME,
    JsonLdModule,
    MetaModule,
    NotificationBarModule,
    PageModule,
    SeoSiteFooterModule,
    SiteFooterModule,
    SiteHeaderModule,
    SiteLayoutModule,
    ssrHttpLogInterceptorProvider,
    TranslateLoaderService,
} from '@impact/ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomUrlSerializer } from './custom-url-serializer';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'bn' }),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderService,
            },
        }),
        MetaModule,
        LayoutModule,
        SiteHeaderModule,
        PageModule.forRoot(),
        SiteLayoutModule,
        SiteFooterModule,
        SeoSiteFooterModule,
        SiteHeaderModule,
        AppRoutingModule,
        NotificationBarModule,
        JsonLdModule,
    ],
    providers: [
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer,
        },
        apiHttpStateInterceptorProvider,
        apiInterceptorProvider,
        {
            provide: LOCALE_ID,
            useValue: 'da-DK',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'DKK',
        },
        {
            provide: DEV_ENVIRONMENT_HOSTNAME,
            useValue: 'dev-bn.dk',
            // useValue: 'staging-bn.dk', // To fetch data from Staging site
            // useValue: 'bn.dk', // To fetch data from Live site
        },
        DecimalPipe,
        ssrHttpLogInterceptorProvider,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
